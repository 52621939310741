




import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import COMPARED_TO_SETTINGS from '@/modules/score/constants/compared-to.settings.constant';
import { $enum } from 'ts-enum-util';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';
// @ts-ignore
import CustomDesignSelect, { Item } from '@/modules/common/components/ui-kit/custom-design-select.vue';

@Component({
    components: { CustomDesignSelect },
})
export default class ComparedScoreDocumentFilter extends Vue {
    @Inject(UserSettingsS) private userSettings!: UserSettingsService;
    @Inject(ScoreServiceS) private scoreService!: ScoreService;

    get comparedTo(): COMPARED_TO_SETTINGS {
        return this.userSettings.storeState.comparedTo;
    }

    set comparedTo(value: COMPARED_TO_SETTINGS) {
        this.userSettings.storeState.comparedTo = value;
        this.scoreService.switchComparedToGraphs(value);
    }

    get comparedToItems(): Item[] {
        return $enum(COMPARED_TO_SETTINGS).map((value): Item => ({
            value,
            name: this.$t(`score.comparedToSetting.${value}`) as string,
        })).filter(item => item.value !== COMPARED_TO_SETTINGS.MARKET);
    }
}
