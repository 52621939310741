




























import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';

@Component
export default class ScoreTooltipWrapper extends Vue {
    @Inject(ScoreServiceS) protected scoreService!: ScoreService;

    get thresholds() {
        return this.scoreService.scoreThresholdsRange;
    }

    get colors() {
        return this.scoreService.thresholdColors;
    }
}
