

























import { Component, Prop, Vue } from 'vue-property-decorator';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';

@Component({
    components: {
        Tooltip,
    },
})
export default class ScoreGraphLabels extends Vue {
    @Prop({ required: true })
    labelMinMax!: string;

    @Prop({ type: Boolean })
    skeleton!: boolean;
}
