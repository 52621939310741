



















import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';

@Component({
    components: { CustomPopover },
})
export default class Tooltip extends Vue {
    @Prop({
        required: false,
        validator(value: string): boolean {
            return value === 'top' || value === 'top-start' || value === 'top-end'
                || value === 'right' || value === 'right-start' || value === 'right-end'
                || value === 'bottom' || value === 'bottom-start' || value === 'bottom-end'
                || value === 'left' || value === 'left-start' || value === 'left-end';
        },
        default: 'right',
    })
    placement!: 'top' | 'top-start' | 'top-end'
    | 'right' | 'right-start' | 'right-end'
    | 'bottom' | 'bottom-start' | 'bottom-end'
    | 'left' | 'left-start' | 'left-end';

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    })
    isEmpty!: boolean;

    @Prop({
        required: false,
        default: true,
        type: Boolean,
    })
    haveArrow!: boolean;

    @Prop({
        required: false,
        default: 10,
        type: Number,
    })
    offset!: number;
}
