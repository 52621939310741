




























































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import HomeHotelTitle from '@/modules/home/components/home-hotel-title.vue';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';

import BestWorstHotelSection from '../components/home/best-worst-hotel-section.vue';
import ScoreGraphSection from '../components/home/score-graph-section.vue';
import CiScoreSection from '../components/home/ci-score-section.vue';
import ClusterScoresService, { ClusterScoresServiceS } from '../modules/score/score.service';

@Component({
    components: {
        PageWrapper,
        HomeHotelTitle,
        CiScoreSection,
        ScoreGraphSection,
        Tooltip,
        BestWorstHotelSection,
    },
})
export default class HomeClusterPage extends Vue {
    @Inject(ClusterScoresServiceS) clusterScoresService!: ClusterScoresService;

    displayAll: boolean = false;

    toggleDisplay() {
        this.displayAll = !this.displayAll;
    }

    get sortedHotels() {
        return this.clusterScoresService.scoreRank
            .sort((a, b) => a.position! - b.position!);
    }

    hotelsSlice() {
        if (this.sortedHotels.length > 10) {
            if (this.displayAll) {
                return this.sortedHotels;
            }
            return [...this.sortedHotels.slice(0, 5), ...this.sortedHotels.slice(1).slice(-5)];
        }
        return this.sortedHotels;
    }

    get dateNow() {
        const date = new Date();
        const day = date.getDate();
        const month = date.toLocaleString('en', { month: 'long' });
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }

    get isScoreServiceLoading() {
        return this.clusterScoresService.isLoading;
    }
}
